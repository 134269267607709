import React, { useState } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import styles from "../components/SectionTeam/SectionTeam.module.scss";
import SectionPolicy from "../components/SectionPolicy";


const PolicyPage = () => {
  let [isModalOpen, setIsModalOpen] = useState(false);
  let policyHeading = {
    title : 'POLICY STATEMENT',
    data : '<p> ' +
      'We at Drogevate Solutions Pvt. Ltd (‘Drogevate’) respect the privacy of everyone who visits this website and are ' +
      'committed to maintain the privacy and security of the personal information of all visitors to this website.' +
      ' Our policy on the collection and use of personal information and other information is outlined below.' +
      '</p>'
  };

  let policyPersonalInfo = {
    title: 'PERSONAL INFORMATION',
    data: '<p>' +

      `<ol type="1">
        <li>We do not require personal information to obtain access to most of our website</li>
        <li> We collect personal information from our visitors on a voluntary basis. Personal information may include name, title, company, address, phone number, email address and other relevant data including unique identifiers or as required by local laws and government regulations.</li>
        <br/>
         <div>
         Questions or comments submitted by visitors may also include personal information.
         </div>
        <br/>
        <li>We collect and use personal information for business purposes in order:</li>
         <ul>
            <li>That you may download information about the services and take advantage of certain other features of our website.
            </li>
           <li>To provide information or interact with our services through this website, to your e-mail address or, where you wish it to be sent by post, to your name and postal address.
          </li>
          <li>To seek your feedback or to contact you in relation to those services mentioned  on our website.</li>
          <li>To process orders or applications submitted by you.</li>
          <li>To administer or otherwise carry out our obligations in relation to any agreement you have with you.</li>
          <li>To anticipate and resolve problems with any goods or services supplied to you.</li>
          <li>To process and respond to requests, improve our operations, and communicate with visitors about our products, services and businesses.</li>
          <li>To allow you to subscribe to our newsletter.</li>
          </ul>
          <li>  We will not use or share, either within Drogevate or with a third party,
           any information collected at this page for direct marketing purposes. 
           Because of the nature of the Internet, we may transmit the information limited to Drogevate and its affiliates,
            for purposes other than direct marketing, such as for storage, or for carrying out the processing detailed above,
             or because of where our servers are located, but we do not provide or use personal information to unrelated businesses 
             for direct marketing purposes.</li>
          <li>To the extent required or permitted by law, we may also collect, 
          use and disclose personal information in connection with security related or
           law enforcement investigations or in the course of cooperating with authorities or complying with legal requirements.
           </li>
          <li>We may also remove all the personally identifiable information and use the rest for historical,
           statistical or scientific purposes.</li>
          <li>   If you e-mail us, you are voluntarily releasing information to us. Your e-mail address will be used by 
            Drogevate to respond to you.
           We will not use the information that can identify you, such as your e-mail address, for direct marketing purposes.</li>
          <li>In addition, we may have collected similar information from you in the past. 
          By entering this website, you are consenting to the terms of our information privacy policy
           and to our continued use of previously collected information. By submitting your personal information to us, 
           you will be treated as having given your permission for the processing of your personal data as set out in this policy.
</li>
      </ol>`
    +
    '</p>'
  };

  let policyNonPersonalInfo = {
    title : 'NON-PERSONAL INFORMATION',
    data: `<ol type="1">
                <li> At this web site, information sent by your web browser, may be automatically collected.
                 This information typically includes your domain name (the site after the @ in your e-mail address). 
                 It may also contain your user-name (the name before the @ in your e-mail address).
                  Other examples of information collected by our server include the Internet protocol (IP) address
                   used to connect the visitor’s computer to the Internet, operating system and platform,
                    the average time spent on our website, pages viewed, information searched for, access times,
                     websites visited before and a visitor visits our website, and other relevant statistics.
                      The amount of information sent depends on the settings you have on your web browser; 
                      please refer to your browser if you want to learn what information it sends.</li>
                <li>All such information will be used only to assist us in providing an effective service on this website. 
                We may from time to time supply the owners or operators of third party websites from which it is possible to link to our
                 website with information relating to the number of users linking to our website from such third-party website. 
                 You cannot be identified from this information.
                 </li>
                <li>We use the information we automatically receive from your web browser to see which pages you visit within our website, 
                which website you visited before coming to ours, and where you go after you leave.
                 We at Drogevate can then develop statistics that are helpful to understanding how our visitors use this website.
                  We use this information in the aggregate to measure the use of our website and to administer and improve our website.
                   This statistical data is interpreted by Drogevate in its continuing effort to present the website content that visitors are seeking 
                   in a format they find most helpful.</li>
            </ol>
`
  };

  let infoOnComputer = {
    title : 'INFORMATION PLACED ON YOUR COMPUTER',
    data : `<p>
        We may store some information such as cookies on your computer when you look at our website. 
        Cookies are pieces of information that a website transfers to the hard drive of a visitor’s computer for record-keeping purposes. 
        This information facilitates your use of our website and ensures that you do not need to re-enter your details every time you visit it.
         You can erase or choose to block this information from your computer if you want to; 
         please refer to your browser settings to do so. Erasing or blocking such information may limit the range of features available 
         to the visitor on our website. We use also use such information to provide visitors a personalized experience on our website.
          We may use such information to allow visitors to use the website without logging on upon returning, to auto-populate email forms, 
          to make improvements and to better tailor our website to our visitors’ needs.
           We also use this information to verify that visitors meet the criteria required to process their requests.
    </p>
    `
  };

  let securityPolicy = {
    title: 'SECURITY',
    data: `
     <p>We have implemented technology and policies, with the objective of protecting your privacy from unauthorized access and improper use,
   and periodically review the same.</p>
    `
  };

  let thirdPartyPolicy = {
      title: ' THIRD PARTIES',
    data : `<p>For your convenience, this page may contain certain hyperlinks to other Drogevate 
    pages as well as to websites outside Drogevate. In addition, you may have linked to our website from another website. 
    We cannot be responsible for the privacy policies and practices of other websites, 
    even if you access those using links from our website. 
    We can make no promises or guarantees regarding data collection on the hyper-linked pages 
    and on websites that are not owned by Drogevate. We recommend that you check the
     policy of each website you visit, or link from, and contact the owners or operators of such
      websites if you have any concerns or questions.</p>

`
  };

  let contactUsPolicy = {
    title : 'CONTACTING US',
    data: `<p>If you are concerned about our use of your personal information, please contact us at 
        <a href="mailto:support@drogevate.com">support@drogevate.com</a>
     with subject line “privacy”.</p>`
  };
  return (
    <>
      <Navigation openModal={setIsModalOpen} showNavbar={ false } />
      <Layout>
        <SEO title="Drogevate Solutions - Privacy Policy" />
        <section style={
          {
            marginLeft: '14%'
          }
        }>
          <div className={styles.titleContainer}>
            <h2 style={ {
              textAlign : 'left',
              color: '#18898d'
            } }>
              Privacy & Cookie Policy
            </h2>
            <div>
              <SectionPolicy policyHeading= { policyHeading.title } policyData={ policyHeading.data }> </SectionPolicy>
            </div>
            <div>
              <SectionPolicy policyHeading= { policyPersonalInfo.title } policyData={ policyPersonalInfo.data }> </SectionPolicy>
            </div>
            <div>
              <SectionPolicy policyHeading= { policyNonPersonalInfo.title } policyData={ policyNonPersonalInfo.data }> </SectionPolicy>
            </div>
            <div>
              <SectionPolicy policyHeading= { infoOnComputer.title } policyData={ infoOnComputer.data }> </SectionPolicy>
            </div>
            <div>
              <SectionPolicy policyHeading= { securityPolicy.title } policyData={ securityPolicy.data }> </SectionPolicy>
            </div>
            <div>
              <SectionPolicy policyHeading= { thirdPartyPolicy.title } policyData={ thirdPartyPolicy.data }> </SectionPolicy>
            </div>
            <div>
              <SectionPolicy policyHeading= { contactUsPolicy.title } policyData={ contactUsPolicy.data }> </SectionPolicy>
            </div>
          </div>
        </section>
      </Layout>
      <Footer />


    </>
  );
};

export default PolicyPage;
