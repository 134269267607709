import React from 'react';
import styles from './SectionPolicy.module.scss';

const SectionPolicy = ({policyHeading, policyData}) => {
  return (
    <section className={styles.SectionPolicy}>
      <div className={styles.PolicyContainer}>
        <h2 className={styles.title}>
          {policyHeading}
        </h2>
        <div dangerouslySetInnerHTML={
          {__html: policyData}
        }>
        </div>
      </div>
    </section>
  );
};

export default SectionPolicy;
